import React from 'react';
import { graphql } from 'gatsby';
import { Heading, Link, MainContainer, PageContainer } from '../common';
import SideBar from '../components/SideBar/SideBar';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { styleVars } from '../lib/helpers/style';

const { mobileBreakpoint } = styleVars;

export const query = graphql`
  {
    drupal {
      nodeQuery(filter: { conditions: [{ field: "type", value: "home_page", operator: EQUAL }] }) {
        entities {
          ... on Drupal_NodeHomePage {
            fieldPopularTopics {
              entity {
                ... on Drupal_ParagraphPopularTopics {
                  fieldTopicsLink {
                    entity {
                      entityLabel
                      entityUrl {
                        path
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const breadcrumbs = [{ text: 'Home', url: '/' }, { text: '404' }];

const NotFoundPage = ({ data: { drupal } }) => {
  const { fieldPopularTopics } = drupal.nodeQuery.entities[0];
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <MainContainer row className={`pt-8 pt-${mobileBreakpoint}-9 pb-${mobileBreakpoint}-13`}>
        <SideBar />

        <PageContainer>
          <Heading as="h1" color="gold">
            404 Error
          </Heading>
          <Heading as="h2" styledAs="h4">
            We’re sorry, the page you’re looking for was not found.
          </Heading>

          <hr className={`my-6 mb-${mobileBreakpoint}-4`} />

          <Heading as="h3" styledAs="h4" className="mb-2">
            What are you looking for?
          </Heading>

          <p className="h5 font-weight-normal mb-4">Browse our most popular services below.</p>

          {fieldPopularTopics
            .filter(
              ({
                entity: {
                  fieldTopicsLink: { entity },
                },
              }) => entity
            )
            .map(({ entity: { fieldTopicsLink: { entity: { entityLabel, entityUrl: { path } } } } }) => (
              <p className="lead">
                <Link href={path}>{entityLabel}</Link>
              </p>
            ))}

          <hr className={`my-6 d-${mobileBreakpoint}-none`} />
        </PageContainer>
      </MainContainer>
    </>
  );
};

export default NotFoundPage;
